import React from "react"
import { Fade } from "react-awesome-reveal"
import styled from "styled-components"
import SEO from "../components/SEO"

const PageStyles = styled.div`
  /* scroll-snap-type: y mandatory;
  overflow-y: scroll; */
  height: calc(100vh - 150px);
`

const BodyStyles = styled.div`
  /* scroll-snap-align: start; */
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 130px;

  @media (max-width: 900px) {
    padding-left: 90px;
    padding-right: 40px;
    padding-bottom: 120px;
    padding-top: 45px;
    min-height: 0;
  }

  h1 {
    margin-left: -65px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }

  article {
    columns: 1;
    column-width: 330px;
    column-gap: 30px;
  }
`

function contactPage() {
  return (
    <>
      <SEO title="Contact" />
      <Fade triggerOnce>
        <PageStyles>
          <BodyStyles>
            <div>
              <h1>CONTACT</h1>

              <article>
                <p>
                  VERSE COLLECTIVE <br /> Pehebiya Road <br /> Dickwella <br />{" "}
                  Sri Lanka
                </p>
                <p>
                  TEL: <a href="tel:+94 76 319 3027">+94 76 319 3027</a> <br />
                  EMAIL:{" "}
                  <a href="mailto:info@versecollective.com">
                    info@versecollective.com
                  </a>{" "}
                  <br />
                  INSTAGRAM:{" "}
                  <a
                    href="http://instagram.com/verse_collective"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    verse_collective
                  </a>{" "}
                  <br />
                  WEBSITE: www.versecollective.com
                </p>
              </article>
            </div>
          </BodyStyles>
        </PageStyles>
      </Fade>
    </>
  )
}

export default contactPage
